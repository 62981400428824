<template>
 <Layout>
  <v-card>
    <v-card-title>All Scope</v-card-title>

    <v-card-text>
      <v-simple-table>
        <template>
          <v-data-table :headers="tableHeaders" :items="allScopes" sort-by="scope_title" item-key="scope_id" :search="search" :custom-filter="filterScopeByName">
            <template v-slot:top>
              <v-text-field v-model="search" label="Search scope" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item.scopeStatus="{item}">
              <td>{{ getScopeStatus(item.status) }}</td>
            </template>
            <template v-slot:item.actions="{item}">
              <v-card-actions>
                <v-btn class="ma-2" small outlined color="error" @click="deleteScope(item.scope_id)">
                  <v-icon small left>mdi-delete</v-icon> Delete
                </v-btn>
                <v-btn class="ma-2" small outlined color="primary" @click="duplicateScope(item.scope_id)">
                  <v-icon small left>mdi-menu</v-icon> Duplicate
                </v-btn>
                <v-btn class="ma-2" small outlined color="primary" @click="downloadScope(item.scope_id)">
                  <v-icon small left>mdi-arrow-down-bold</v-icon> Download
                </v-btn>
                <router-link class="route-btn" :to="`/scope/edit/${item.scope_id}`">
                  <v-btn class="ma-2" small outlined color="primary">
                    <v-icon small left>mdi-pencil</v-icon> Edit
                  </v-btn>
                </router-link>
              </v-card-actions>
            </template>
          </v-data-table>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>

 </Layout>
</template>

<script>
import Layout from "@/shared/Layout";
import loader from "@/img/filter-loader.gif";

export default {
    components: {
        Layout
    },
    data() {
        return {
            search: '',
            tableHeaders: [
                {
                  text: 'Scope Name',
                  align: 'start',
                  sortable: true,
                  value: 'scope_title',
                },
                {
                  text: 'Scope Status',
                  align: 'start',
                  sortable: false,
                  value: 'scopeStatus',
                },
                {
                  text: 'Scope Deadline',
                  align: 'start',
                  sortable: true,
                  value: 'deadline',
                },
                {
                  text: 'Actions', 
                  value: 'actions', 
                  align: 'start',
                  sortable: false
                },
            ],
            scope_available_status: [
                {
                    text: 'Draft',
                    value: 1
                },
                {
                    text: 'Bid Open',
                    value: 2
                },
                {
                    text: 'Bid Closed',
                    value: 3
                },
                {
                    text: 'Won',
                    value: 4
                },
                {
                    text: 'Lost',
                    value: 5
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('scope/fetchScopeList').then((data)=>{
            // console.log(data);
        })
        .catch((response)=> {
            // console.log(response);
        });
    },
    computed: {
      allScopes(){
          return this.$store.getters['scope/getAllScopes'];
      },
      currentScopeID(){
          return this.$store.getters['scope/getScopeID'];
      },
    },
    methods: {
        getScopeStatus(status_val){
            var self = this;
            var selected_scope_status = self.scope_available_status.filter(function (scope_status) {
              return scope_status.value == status_val;
            });
            return selected_scope_status[0].text;
        },
        downloadScope(scope_id){
            this.$store.dispatch('scope/downloadScope', {scope_id: scope_id}).then((data)=>{
                // console.log(data);
            })
            .catch((response)=> {
                // console.log(response);
            });
        },
        duplicateScope(scope_id){
            this.$store.dispatch('scope/duplicateScope', {scope_id: scope_id}).then((data)=>{
                // console.log(data);
            })
            .catch((response)=> {
                // console.log(response);
            });
        },
        deleteScope(scope_id){
            if (confirm('Are you sure you want to delete the scope?')) {
                this.$store.dispatch('scope/deleteScope', scope_id).then((data)=>{
                  // console.log(data);
                })
                .catch((response)=> {
                  // console.log(response);
                });
            }
        },
        filterScopeByName (value, search, item) {
            return value != null && search != null &&
                typeof value === 'string' &&
                value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
      },
    }
}
</script>
<style>
  .route-btn {
    text-decoration: none;
  }
</style>
