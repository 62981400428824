<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row class="mx-0 my-0"
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer />
             
              </v-toolbar>
              <v-main
                class="px-4 py-4"
                fluid
              >
                <v-img class="my-5 mt-10" :contain="true" :max-height="130" :src="logo" :position="'center center'">
                </v-img>
                <v-card-text class="px-0 py-0">
                  <v-form>
                    <v-text-field
                      label="Email"
                      name="email"
                      prepend-icon="mdi-account"
                      type="email"
                      v-model="email"
                    />
                    
                    <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      v-model="password"
                    />
                  </v-form>
                </v-card-text>
                <div v-if="authMessage.show">
                  <v-alert :type="authMessage.status">
                    <span v-if="typeof authMessage.message == 'string'">{{ authMessage.message }}</span>
                    <ul v-else class="error-messages">
                      <li v-for="messageItem in authMessage.message" :key="messageItem.index">
                        {{ messageItem[0] }}
                      </li>
                    </ul>
                  </v-alert>
                </div>
                <v-card-actions class="pt-2 pb-5">
                  <v-img :contain="true" :max-height="40" :src="loader" :position="'top center'" v-show="loading">
                  </v-img>
                  <v-spacer />
                  <v-btn large class="px-8 py-2" color="primary" @click="submitLogin">Login</v-btn>
                </v-card-actions>
              </v-main>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
  ul.error-messages li {
    list-style: none;
  }
</style>

<script>
  import logo from "@/img/selise-logo.png";
  import loader from "@/img/filter-loader.gif";
  import router from '@/router';
  export default {
    data(){
      return {
        email: '',
        password: '',
        logo: logo,
        loader: loader,
        loading: false,
        authMessage: {
          show: false,
          status: 'error',
          message: 'success'
        }
      }
    }, 
    props: {
      source: String,
    },
    methods: {
      submitLogin(){
        if(this.email && this.password) {
          this.loading = true;
          var credential = {
            email: this.email,
            password: this.password
          }
          this.$store.dispatch('authentication/login', credential).then((data)=>{
            this.loading = false;
            // console.log(data);
            this.authMessage.message = 'Login successful. You are redirecting to dashboard';
            this.authMessage.status = 'success';
            this.authMessage.show = 'true';
            setTimeout(function(){ router.push('/dashboard'); }, 2000);
          })
          .catch((response)=> {
            this.loading = false;
            // console.log(response);
            this.authMessage.message = this.$store.getters['authentication/getErrors'].errors;
            this.authMessage.status = 'error';
            this.authMessage.show = 'true';
          });
        }
      }
    }
  }
</script>