import ApiService from '../api/api.service';
import Errors from '../form';

const state = {
    componentId: 0,
    allComponents: [],
    errors: new Errors(),
    fullComponentList: [],
    productDetails: {},
    scopeEpic: {}
};

const getters = {
    getComponentID(state) {
        return state.componentId;
    },
    getComponentList(state){
        return state.allComponents;
    },
    getErrors(state){
        return state.errors;
    },
    getFullComponentList(state){
        return state.fullComponentList;
    },
    getProductDetails(state) {
        return state.productDetails;
    },
    getScopeEpic(state) {
        return state.scopeEpic;
    }
};

const mutations = {
    setError(state, {target, message}) {
        state.errors.clear();
        state.errors.record(message);
    },
    setComponentID(state, id) {
        state.componentId = id;
    },
    clearErrors(state) {
        state.errors = new Errors();
    },
    setFullComponentList(state, data){
        state.fullComponentList = data;
    },
    setProductDetails(state, data) {
        state.productDetails = data;
    },
    setScopeEpic(state, data) {
        state.scopeEpic = data;
    },
    removeComponent(state, componentId) {
        state.fullComponentList = state.fullComponentList.filter(component => component.component_id !== componentId);
    }
}

const actions = {
    addNewComponent(context, component_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/component-create', {...component_arguments})
            .then(({data}) => {
                context.commit('setComponentID', data.success.component_id);
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'create_component', message: response.data.error});
                reject(response);
            });
        });
    },
    updateComponent(context, component_arguments) {
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/component-update', {...component_arguments})
            .then(({data}) => {
                context.commit('setComponentID', data.success.component_id);
                context.commit('clearErrors');
                resolve(data);
            })
            .catch(({response}) => {
                context.commit('setError', {target: 'update_component', message: response.data.error});
                reject(response);
            });
        });
    },
    fetchComponentList(context) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/component-list').then(({data})=>{
                context.commit('setFullComponentList', data.success);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    fetchComponent(context, componentId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/component', `?component_id=${componentId}`).then(({data})=>{
                context.commit('setComponentID', data.success.component_id);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    resetComponentID(context) {
        context.commit('setComponentID', 0);
    },
    deleteComponent(context, componentId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`api/component-delete?component_id=${componentId}&force=false`).then(({data})=>{
                context.commit('clearErrors');
                context.commit('removeComponent', componentId);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};