import ApiService from '../api/api.service';

const DefaultPricingData = () => {
    return {
        dp_id: '',
        scope_id: 0,
        overview: `<p>The pricing is based on the scope that has been discovered thus far, until the creation of this document. 
        Should the scope undergo changes/additions, it may have a subsequent impact on pricing and development efforts.</p>`,
        price_breakdown: [],
        note: `<ul> <li> <p>Payment terms: 30% project initiation, 70% UAT</p> </li> <li> <p>Prices are subject to local 
        taxes.</p> </li> <li> <p>Hosting and maintenance will be charged on a monthly basis and will start as soon as 
        the project is live.</p> </li> <li> <p>Any unused support hours will be forfeited at the end of the 
        month.</p> </li> <li> <p>Additional support hours will be charged at CHF 37.5/hour</p> </li> </ul>`,
        payment_terms: `<p>This section will clearly mention how many installments the clients are allowed to make the payments 
        in. Inside the platform, a format creator should already be in place which will allow editor to:</p> <ol> <li>Min. 30 
        up to 50% with signature</li> <li>Min. 30 up to 50% with start UAT</li> <li>Final 20% with Handover</li> </ol>`,
        change_request: `<ul> <li> <p>Any changes in the process or features will result in re-estimation of the price and 
        time given for this project.</p> </li> <li> <p>SELISE will start working on creating the design layout only when ALL 
        CONTENT has been provided by the client.</p> </li> <li> <p>The client is responsible for all content on the website, 
        as well as translations. SELISE will provide with final confirmation of the order and a design freeze date and based 
        on that a UAT delivery date.</p> </li> <li> <p>After agreed Design Freeze with the client SELISE will confirm again 
        (or communicate a readjusted) UAT delivery date. Changes in Design after agreed - Design Freeze date will impact UAT 
        delivery date.</p> </li> <li> <p>Actual delivery dates for the concept will be decided upon discussion with the 
        client and final confirmation of the order, with reference to the timeline estimation given in this scope statement.
        </p> </li> <li> <p>The client will provide SELISE TWO WORKING DAYS to implement feedback on any design/development.</p> 
        </li> </ul>`,
        handover: `<p>The release will be accepted by the Client prior to deploying it to production. Client will be part of the 
        development process and be demonstrated partial deliveries in order to align expectations and real development. Client 
        will furthermore ensure cooperation in order to circumvent delays in development due to the above dependencies.</p>`,
        disclaimer: `<p>The timelines listed in this document are based on the currently discovered scope and may vary. 
        For certain modules, additional Product Discovery Sessions may be required. Any unforeseen technical 
        bottlenecks and requests beyond the scope will affect the completion time and cost.</p>`
    }
};

const DefaultSaasModelData = () => {
    return {
        saas_id: 0,
        is_active: false,
        payment_period: 36,
        downpayment: 0,
        interest_rate: 0,
        title: 'SELISE is offering a SaaS model for the payment scheme of the project. The payment can either be done on a monthly basis or a yearly basis.',
        description: 'Project installments, {{currentYear}} onwards',
        termination: 'Earliest Termination for the SaaS Model is after {{paymentPeriod}} months.'
    }
};

const state = {
    pricing_data: DefaultPricingData(),
    saas_model: DefaultSaasModelData()
};

const getters = {
    getPricingData(state){
        return state.pricing_data;
    },
    getOrderedBreakdown(state){
        var epic_breakdown = [];
        var additional_breakdown = [];
        state.pricing_data.price_breakdown.forEach(function(breakdown, index){
          if(breakdown.epic_data) {
            breakdown.epic_title = breakdown.epic_data.title;
            epic_breakdown.push(breakdown);
          } else {
            additional_breakdown.push(breakdown);
          }
        });

        return epic_breakdown.concat(additional_breakdown);
    },
    getPriceSum(state){
        var sum = 0;
        state.pricing_data.price_breakdown.forEach(function(breakdown, index){
            if(breakdown.price) {
                sum += breakdown.price;
            }
        });
        return sum;
    },
    getPricingID(state){
        return state.pricing_data.dp_id;
    },
    getSaasModelData(state){
        return state.saas_model;
    }
};

const mutations = {
    setPricingData(state, pricing_arg){
        var data = pricing_arg.pricing_data;
        if(data) {
            state.pricing_data.scope_id = data.scope_id;
            state.pricing_data.dp_id = data.dp_id;
            state.pricing_data.overview = data.overview;
            state.pricing_data.price_breakdown = data.price_breakdown;
            state.pricing_data.note = data.note;
            state.pricing_data.payment_terms = data.payment_terms;
            state.pricing_data.change_request = data.change_request;
        } else {
            state.pricing_data = DefaultPricingData();
            state.pricing_data.scope_id = pricing_arg.scope_id;
        }
    },
    addNewPricingBreakdown(state, epic_price_break){
        var price_break = epic_price_break.breakdown_data;
        price_break.epic_data = epic_price_break.epic_data;
        if(!state.pricing_data.dp_id){
            state.pricing_data.dp_id = price_break.dp_id;
        }

        if (typeof price_break.price  == 'undefined') {
            price_break.price = 0;
        }
        if (typeof price_break.remarks  == 'undefined') {
            price_break.remarks = '';
        }

        state.pricing_data.price_breakdown.push(price_break);
    },
    updateBreakdownList(state, epic_price_break){
        var epic_index = state.pricing_data.price_breakdown.findIndex((breakdown => breakdown.pb_id == epic_price_break.pb_id));
        state.pricing_data.price_breakdown[epic_index].price = epic_price_break.price;
        state.pricing_data.price_breakdown[epic_index].remarks = epic_price_break.remarks;
        if(!state.pricing_data.price_breakdown[epic_index].epic_data) {
            state.pricing_data.price_breakdown[epic_index].epic = epic_price_break.epic;
        }
    },
    removePricingBreakdown(state, priceBreakdownId){
        state.pricing_data.price_breakdown = state.pricing_data.price_breakdown.filter(breakdown => breakdown.pb_id !== priceBreakdownId);
    },
    removeEpicBreakdown(state, epicId){
        state.pricing_data.price_breakdown = state.pricing_data.price_breakdown.filter(breakdown => breakdown.epic_id !== epicId);
    },
    changePricingInfo(state, pricing_info){
        if(!state.dp_id) {
            state.dp_id = pricing_info.dp_id;
        }
        state.overview = pricing_info.overview;
        state.note = pricing_info.note;
    },
    updateEpicPricing(state, epic_data){
        var break_index = state.pricing_data.price_breakdown.findIndex((breakdown => breakdown.epic_id == epic_data.epic_id));
        state.pricing_data.price_breakdown[break_index].epic_title = epic_data.title;
        state.pricing_data.price_breakdown[break_index].epic_data.title = epic_data.title;
    },
    setSaasModelData(state, saas_term){
        if (saas_term.length) {
            state.saas_model = saas_term[0];
        }
    }
};

const actions = {
    fetchEpicPricingBreakdown(context, scope_id){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/dev-pricing-data', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setPricingData', {scope_id: scope_id, pricing_data: data.success});
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    saveDevPricing(context, pricing_data){
        return new Promise ((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/dev-pricing-save', {...pricing_data})
            .then(({data})=>{
                context.commit('changePricingInfo', data.success);
                resolve(data);
            })
            .catch(({response}) => {
                reject(response);
            });
        });
    },
    savePricingBreakdown(context, breakdown_data){
        if(!breakdown_data.pb_id){
            return new Promise ((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/price-breakdown-save', {...breakdown_data})
                .then(({data})=>{
                    context.commit('addNewPricingBreakdown', {breakdown_data: data.success});
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
            });
        } else {
            return new Promise ((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/price-breakdown-update', {...breakdown_data})
                .then(({data})=>{
                    context.commit('updateBreakdownList', data.success);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
            });
        }
    },
    deletePricingBreakdown(context, priceBreakdownId){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post('api/price-breakdown-delete', {pb_id: priceBreakdownId}).then(({data})=>{
                context.commit('removePricingBreakdown', priceBreakdownId);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    fetchSaasModel(context, scope_id){
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get('api/get-saas-term', `?scope_id=${scope_id}`).then(({data})=>{
                context.commit('setSaasModelData', [data.success]);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    saveSaasModel(context, saasModel){
        if (saasModel.saas_id == 0) {            
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/create-saas-term', saasModel).then(({data})=>{
                    context.commit('setSaasModelData', [data.success]);
                    resolve(data);
                }).catch(({response}) => {
                    reject(response);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post('api/update-saas-term', saasModel).then(({data})=>{
                    context.commit('setSaasModelData', [data.success]);
                    resolve(data);
                }).catch(({response}) => {
                    reject(response);
                });
            });
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};